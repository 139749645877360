import { ref, watch, computed } from "@vue/composition-api";
import store from "@/store";

// Notification
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default function RegularizationList() {
    // Use toast
    const toast = useToast();

    const refUserListTable = ref(null);

    // Table Handlers
    const tableColumns = [
        { key: "select", sortable: false, label: "Select" },
        { key: "user.name", sortable: false, label: "Employee Name" },
        { key: "dates", sortable: false, label: "Dates" },
        { key: "from_time", sortable: false, label: "From Time" },
        { key: "to_time", sortable: false, label: "To Time" },
        { key: "status", sortable: true, label: "Status" },
        { key: "actions" }
    ];
    const perPage = ref(10);
    const totalRegularizationList = ref(0);
    const currentPage = ref(1);
    const perPageOptions = [10, 25, 50, 100];
    const searchQuery = ref("");
    const sortBy = ref("id");
    const isSortDirDesc = ref(true);
    const statusFilter = ref(null);
    const regularizationsMetaData = ref([]);
    const regularizationStatusOption = ref([]);
    const createdByFilter = ref(null);
    const managerFilter = ref(null);

    const dataMeta = computed(() => {
        const localItemsCount = refUserListTable.value
            ? refUserListTable.value.localItems.length
            : 0;
        return {
            from:
                perPage.value * (currentPage.value - 1) +
                (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalRegularizationList.value
        };
    });

    const refetchData = () => {
        refUserListTable.value.refresh();
    };

    watch(
        [currentPage, perPage, searchQuery, statusFilter,managerFilter, createdByFilter],
        () => {
            refetchData();
        }
    );

    const fetchRegularizationList = (ctx, callback) => {
        store
            .dispatch("app-regularizations/fetchRegularizations", {
                q: searchQuery.value,
                perPage: perPage.value,
                page: currentPage.value,
                sortBy: sortBy.value,
                sortDesc: isSortDirDesc.value,
                status: statusFilter.value,
                createdBy: createdByFilter.value,
                manager: managerFilter.value,
            })
            .then(response => {
                const regularization = response.data.data;
                const { total } = response.data.meta;
                regularizationStatusOption.value = response.data.status;

                callback(regularization);
                totalRegularizationList.value = total;
                regularizationsMetaData.value = regularization;
            })
            .catch(error => {
                console.error(error);
                toast({
                    component: ToastificationContent,
                    props: {
                        title: "Error fetching regularizations",
                        icon: "AlertTriangleIcon",
                        variant: "danger"
                    }
                });
            });
    };

    const resolveUserStatusVariant = status => {
        if (status == 0) return "warning";
        if (status == 1) return "success";
        if (status == 2) return "danger";
        return "primary";
    };

    return {
        fetchRegularizationList,
        tableColumns,
        perPage,
        currentPage,
        totalRegularizationList,
        regularizationsMetaData,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refUserListTable,
        refetchData,
        resolveUserStatusVariant,
        regularizationStatusOption,
        statusFilter,
        createdByFilter,
        managerFilter
    };
}
